export const SET_MY_STATES = 'SET_MY_STATES';

export const SET_TODAYS_DATE = 'SET_TODAYS_DATE';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';

export const SET_ALERT_DIALOG = 'SET_ALERT_DIALOG';
export const CLOSE_ALERT_DIALOG = 'CLOSE_ALERT_DIALOG';

export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_DIALOG = 'SET_LOADING_DIALOG';
export const SET_ON_SCREEN_DEBUG_MESSAGE = 'SET_ON_SCREEN_DEBUG_MESSAGE';

export const SET_SIGN_IN_REG_DIALOG_TYPE = 'SET_SIGN_IN_REG_DIALOG_TYPE';
export const SET_SIGN_IN_REG_DIALOG_TITLE = 'SET_SIGN_IN_REG_DIALOG_TITLE';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const SET_ID_TOKEN = 'SET_ID_TOKEN';
export const SET_CUSTOM_ID = 'SET_CUSTOM_ID';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';

export const SET_GYM_MEMBER = 'SET_GYM_MEMBER';
export const SET_GYM_MEMBERS = 'SET_GYM_MEMBERS';
export const SET_GYM_MEMBER_DIALOG_DATA = 'SET_GYM_MEMBER_DIALOG_DATA';
export const SET_GYM_MEMBER_DIALOG_OPEN = 'SET_GYM_MEMBER_DIALOG_OPEN';

export const SET_EXERCISE_ITEMS = 'SET_EXERCISE_ITEMS';
export const SET_EXERCISE_ITEM_DIALOG_DATA = 'SET_EXERCISE_ITEM_DIALOG_DATA';
export const SET_EXERCISE_ITEM_DIALOG_OPEN = 'SET_EXERCISE_ITEM_DIALOG_OPEN';

export const SET_WORKOUT_ITEMS = 'SET_WORKOUT_ITEMS';
export const SET_WORKOUT_DIALOG_DATA = 'SET_WORKOUT_DIALOG_DATA';
export const SET_WORKOUT_DIALOG_OPEN = 'SET_WORKOUT_DIALOG_OPEN';

export const SET_GYM_DAY_ITEMS = 'SET_GYM_DAY_ITEMS';
export const SET_GYM_DAY_DIALOG_DATA = 'SET_GYM_DAY_DIALOG_DATA';
export const SET_GYM_DAY_DIALOG_OPEN = 'SET_GYM_DAY_DIALOG_OPEN';

export const SET_PHOTOS = 'SET_PHOTOS';
export const SET_PHOTO_DIALOG_DATA = 'SET_PHOTO_DIALOG_DATA';
export const SET_PHOTO_DIALOG_OPEN = 'SET_PHOTO_DIALOG_OPEN';
export const SET_IMAGE_EDITOR_DATA = 'SET_IMAGE_EDITOR_DATA';

export const SET_TODAYS_WORKOUTS = 'SET_TODAYS_WORKOUTS';

export const SET_EXERCISE_DAY = 'SET_EXERCISE_DAY';

export const SET_EXERCISES_PREVIOUS = 'SET_EXERCISES_PREVIOUS';

import React from 'react';

const HelpItem3 = () => {
  return (
    <>
      <p className='p'>
        Select your price points by clicking on the $ button. One or several can
        be selected. The icons represent:
      </p>
      <ul style={{ paddingLeft: '1.5rem' }}>
        <li>
          <i className='icon-dollar_1'></i> 0-20 dollars
        </li>
        <li>
          <i className='icon-dollar_2'></i> 20-35 dollars
        </li>
        <li>
          <i className='icon-dollar_3'></i> 35 and up dollars
        </li>
      </ul>
    </>
  );
};

export default HelpItem3;
